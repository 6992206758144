import React, { useState } from "react";
import { Loader } from "../constant/loader/Loader"; // Separate service to import common loader
import { useAuth0 } from "@auth0/auth0-react";
import "./login.css";

/*
 * Name: Login.tsx
 * Description: Login component for authenticating users with Auth0.
 * Version: 1.0.0
 */

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(0);
  const { loginWithRedirect } = useAuth0(); // Auth0 hook for login redirection

  const handleLoginBtn = () => {
    setLoading(1);
    loginWithRedirect();
  };

  return (
    <>
      {/* Loading indicator shown when the form is submitting */}
      {loading ? <Loader /> : ""}{" "}
      <div className="bg-image">
        {" "}
        <div className="login-form row">
          {" "}
          {/* login form */}
          <div className="col-12 col-md-6 col-lg-6 ">
            {" "}
            {/* Left side of the screen for the login form */}
            <div className="logo-container gradient-text">Logo</div>{" "}
            <div className="d-flex justify-content-center">
              <div>
                <div className="position-relative">
                  <div className="login-container">
                    <p className="sub-heading">
                      Log in using Auth0 or Okta for secure authentication.
                    </p>
                  </div>
                </div>
                <button className="login-btn" onClick={handleLoginBtn}>
                  LOGIN
                </button>
              </div>
            </div>
          </div>
          {/* Right side of the screen with background image */}
          <div className="login-bg-image col-6 col-md-6 col-lg-6 "></div>
        </div>
      </div>
    </>
  );
};
