import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'; // Importing necessary functions from Redux Toolkit
import apiClient from '../../services/FetchClient'; // Importing the API client to handle HTTP requests
import { boolean } from 'yup';
import { Address } from './authSlice';
import axios from 'axios';

/*
 * Name: transactionSlice.ts
 * Description: Configuration of the Redux store for the application, incorporating slices for state management.
 * Version: 1.1.0
 */


interface TransactionById {
    id: number;

}
// Define the interfaces for category and subcategory
interface Category {
    id: number;
    name: string;
}

interface SubCategory {
    id: number | null;
    name: string;
}

// Define the CategoryData interface, which now includes an array of subCategories
interface CategoryData {
    id: number;
    name: string;
    subCategory: SubCategory[]; // Updated to handle multiple subCategories
}
interface vehicleDetails {
    vehicleId: number;
    registrationNo: string;
    description: string;
}
interface VehicleInfoAPI {
    registrationNo: string;
    description: string;
}
type VehicleDetailsPayload = {
    vehicleDetails: VehicleInfoAPI[];
};
interface AlertData {
    icon?: string;
    title?: string;
    text?: string;
    timer?: number;
    position?: string;
    confirmButtonText?: string; // Optional, to customize button text
}
interface profileInfo {
    businessName: string;
    imageURL: string;
    businessEmail: string;
    authEmail: string;
    authName: string;
    abn: number;
    acn: number;
    offNum: number;
    address: Address;
    vehicleDetails: vehicleDetails[];
}
// Define the structure for the transaction state in the Redux store
interface TransactionState {
    alertStatus: boolean;
    alertData: AlertData;
    profileInfo: profileInfo | null;
    validAuthZero: boolean;  // Represents if the system has a valid auth state
    transactionList: any[];  // List of transactions (could be more specific than 'any[]')
    categories: CategoryData[];  // List of categories, each with subcategories
    userVehicle: vehicleDetails[];
    status: boolean;  // Status flag for loading or success
    error: string;  // Error message in case of failure
    isPending: boolean;
    transactionDetails: {},
}

// Define the structure for a single transaction's data
interface TransactionValue {
    documentNumber: string;  // Unique identifier for the transaction
    transactionType: "EXPENSE" | "INCOME";  // Type of transaction: either EXPENSE or INCOME
    description: string;  // Description of the transaction
    date: string;  // Transaction date (can be adjusted to Date type if needed)
    expenseAmount: number;  // Amount for expense type transaction
    expenseGst: number;  // GST for expense transaction
    incomeAmount: number;  // Amount for income type transaction
    incomeGst: number;  // GST for income transaction
    payUsing: string;  // Payment method used (could be an enum)
    category: Category;  // Associated category of the transaction
    subCategory: SubCategory;  // Associated subcategory of the transaction
}

// Define the structure for a Fuel Transaction data
interface FuelTransaction {
    vehicle: string;
    fuelType: string;
    fuelRetailer: string;
    location: string;
    costPerLitre: string;
    date: string;
    fuelQuantity: string;
    odometer?: string;
    totalCost: string;
    gst?: string;
}

interface FindData {
    transactionType?: string;
    documentNumber?: string;
    fromDate?: Date;
    toDate?: Date;
    state?: string;
    description?: string;
    categoryId?: number;
    subCategoryId?: number;
    category?: string
    subCategory?: string
    fromAmount?: number;
    toAmount?: number;
    paidUsing?: string;
}

// Define the initial state for the transaction slice
const initialState: TransactionState = {
    alertData: {},
    profileInfo: null,
    transactionDetails: {},
    alertStatus: false,
    userVehicle: [],
    validAuthZero: false,  // Initial auth state is false
    transactionList: [],  // Empty transaction list at start
    categories: [  // Default categories structure with one placeholder category
        {
            id: 2,
            name: "",  // Empty name, should be populated when categories are fetched
            subCategory: [
                {
                    id: 0,
                    name: ""  // Empty subcategory, will be filled with actual data
                },
            ]
        }
    ],
    status: false,  // Status flag for loading or success
    error: "",  // Error message, initially empty
    isPending: false,
};

// Async thunk to fetch the transaction list from the API
export const getTransactionList = createAsyncThunk(
    'transaction/getList',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/transaction`);  // Sending GET request to fetch transactions
            if (response.data) {
                return response.data;  // Return fetched data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch transactions');  // Reject on error
        }
    }
);

// Async thunk to fetch categories from the API
export const getCategories = createAsyncThunk(
    'transaction/getCategories',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/category`);  // Sending GET request to fetch categories
            if (response.data) {
                return response.data;  // Return fetched category data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch categories');  // Reject on error
        }
    }
);

// Async thunk to fetch getUserVehicle from the API
export const getUserVehicle = createAsyncThunk(
    'transaction/getUserVehicle',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/user-vehicle `);  // Sending GET request to fetch categories
            if (response.data) {
                return response.data;  // Return fetched category data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch user-vehicle');  // Reject on error
        }
    }
);

// Async thunk to fetch getUserDetails from the API
export const getUserDetails = createAsyncThunk(
    'transaction/getUserDetails',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/userprofile`);  // Sending GET request to fetch categories
            if (response.data) {
                return response.data;  // Return fetched category data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch user-vehicle');  // Reject on error
        }
    }
);

// Async thunk to add a new transaction to the system
export const addTransaction = createAsyncThunk(
    "transaction/addTransaction",  // Action type
    async (body: TransactionValue, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/transaction`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                return response.data;  // Return response data if transaction is added successfully
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);
// Async thunk to add a new transaction to the system
export const addVehicleDetails = createAsyncThunk(
    "transaction/addVehicleDetails",  // Action type
    async (body: VehicleDetailsPayload, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/addVehicleDetails`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                return response;  // Return response data if transaction is added successfully
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);
// Async thunk to add a new transaction to the system
export const transactionById = createAsyncThunk(
    "transaction/transactionById",  // Action type
    async (body: TransactionById, thunkAPI) => {  // Async function to send transaction data
        const { id } = body;
        try {
            const response = await apiClient.get(
                `${process.env.REACT_APP_API_URL}/transaction/${id}`
            );
            if (response.data) {
                return response;  // Return response data if transaction is added successfully
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);

// Async thunk to creaet-fuel-transaction to the system
export const createFuelTransaction = createAsyncThunk(
    "transaction/createFuelTransaction",  // Action type
    async (body: FuelTransaction, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/fuel/transaction`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                return response;  // Return response data if transaction is added successfully
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);

// Async thunk to creaet-fuel-transaction to the system
export const findTransaction = createAsyncThunk(
    "transaction/findTransaction",  // Action type
    async (body: FindData, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/transactions/find`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                return response.data;  // Return response data if transaction is added successfully
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);

// Create the Redux slice for managing transactions
const transactionSlice = createSlice({
    name: 'transaction',  // Slice name
    initialState,  // Initial state defined above
    reducers: {
        // Action to mark the system as authenticated (validAuthZero)
        systemValidAuthZero: (state) => {
            state.validAuthZero = true;  // Set validAuthZero to true
            localStorage.setItem("systemFormStatus", state.validAuthZero.toString());  // Store the authentication state in localStorage
            return state;
        },
        // Action to mark the system as authenticated (validAuthZero)
        clearTransactionDetails: (state) => {
            state.transactionDetails = {}
        },
        // Action to mark the system as authenticated (validAuthZero)
        showAlert: (state, action: PayloadAction<{}>) => {
            state.alertStatus = true;  // Set validAuthZero to true
            state.alertData = action.payload
        },
        // Action to mark the system as authenticated (validAuthZero)
        hideAlert: (state) => {
            state.alertStatus = false;  // Set validAuthZero to true
        },
    },
    extraReducers: (builder) => {
        // Handle the async thunk actions for fetching transaction list
        builder
            .addCase(getTransactionList.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getTransactionList.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset any previous errors
                state.isPending = false;
                state.transactionList = action.payload;  // Update the transaction list with the fetched data
            })
            .addCase(getTransactionList.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get transaction list';  // Set error message
                state.isPending = false;
            });

        // Handle the async thunk actions for fetching categories
        builder
            .addCase(getCategories.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getCategories.fulfilled, (state, action: PayloadAction<CategoryData[]>) => {
                state.status = true;  // Set status to success
                state.categories = action.payload;
                state.isPending = false;
            })
            .addCase(getCategories.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching categories
        builder
            .addCase(getUserVehicle.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getUserVehicle.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = true;  // Set status to success
                state.userVehicle = action.payload.vehicleDetails;
                state.isPending = false;
            })
            .addCase(getUserVehicle.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching categories
        builder
            .addCase(getUserDetails.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getUserDetails.fulfilled, (state, action: PayloadAction<profileInfo>) => {
                state.status = true;  // Set status to success
                state.profileInfo = action.payload;
                state.isPending = false;
            })
            .addCase(getUserDetails.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching categories
        builder
            .addCase(transactionById.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(transactionById.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = true;  // Set status to success
                // console.log(action.payload, "action.payloadaction.payload")
                state.transactionDetails = action.payload.data;
                state.isPending = false;
            })
            .addCase(transactionById.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching categories
        builder
            .addCase(addVehicleDetails.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(addVehicleDetails.fulfilled, (state) => {
                state.status = true;  // Set status to success
                state.isPending = false;
            })
            .addCase(addVehicleDetails.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });

        // Handle the async thunk actions for adding a new transaction
        builder
            .addCase(addTransaction.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(addTransaction.fulfilled, (state) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset error on success
                state.isPending = false;
            })
            .addCase(addTransaction.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in add transaction';  // Set error message
                state.isPending = false;
            });

        // Handle the async thunk actions for Create Fuel Transaction
        builder
            .addCase(createFuelTransaction.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(createFuelTransaction.fulfilled, (state, action: any) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset error on success
                state.isPending = false;
            })
            .addCase(createFuelTransaction.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in create Fuel Transaction';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for Create Fuel Transaction
        builder
            .addCase(findTransaction.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(findTransaction.fulfilled, (state, action: PayloadAction<[]>) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset error on success
                state.transactionList = action.payload;  //
                state.isPending = false;
            })
            .addCase(findTransaction.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in find Transaction';  // Set error message
                state.isPending = false;
            });
    },
});

// Exporting actions to be used in components
export const { systemValidAuthZero, hideAlert, showAlert, clearTransactionDetails } = transactionSlice.actions;
// Export the reducer for integration with the Redux store
export default transactionSlice.reducer;
