import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'; // Importing necessary functions from Redux Toolkit
import apiClient from '../../services/FetchClient'; // Importing the API client to handle HTTP requests

/*
 * Name: authSlice.ts
 * Description: Configuration of the Redux store for the application, incorporating slices for state management.
 * Version: 1.1.0
 */
export interface Address {
    streetAddress?: string;
    poBoxAddress?: string;
    suburb: string;
    postcode: string;
    state: string;
}
export interface vehicleDetails {
    registrationNo: string,
    description: string,
}

// Define the structure for a single transaction's data
interface SystemSetupValue {
    businessName: string;
    emailId?: string;
    abn: string;
    acn?: string;
    phoneNumber: string;
    address: Address;
    vehicleDetails: vehicleDetails[];
}

// Define the initial state for the transaction slice
const initialState = {
    userIsRegister: false,  // Initial auth state is fals
    status: false,  // Status flag for loading or success
    error: "",  // Error message, initially empty
    isPending: false,
};

// Async thunk to fetch the transaction list from the API
export const userIsRegister = createAsyncThunk(
    'auth/userIsPresent',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/user`);  // Sending GET request to fetch transactions
            if (response.data) {
                return response.data;  // Return fetched data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch transactions');  // Reject on error
        }
    }
);
// Async thunk to add a new transaction to the system
export const addUserDetails = createAsyncThunk(
    "auth/systemSetupValue",  // Action type
    async (body: SystemSetupValue, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/userprofile`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                return response.data;  // Return response data if transaction is added successfully
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);


// Create the Redux slice for managing transactions
const authSlice = createSlice({
    name: 'auth',  // Slice name
    initialState,  // Initial state defined above
    reducers: {},
    extraReducers: (builder) => {
        // Handle the async thunk actions for fetching transaction list
        builder
            .addCase(userIsRegister.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(userIsRegister.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset any previous errors
                localStorage.setItem("systemFormStatus", action.payload.message);
                state.userIsRegister = action.payload.message;
                state.isPending = false;
            })
            .addCase(userIsRegister.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get user Is Register';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching transaction list
        builder
            .addCase(addUserDetails.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(addUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset any previous errors
                state.isPending = false;
            })
            .addCase(addUserDetails.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get user Is Register';  // Set error message
                state.isPending = false;
            });
    },
});

// Export the reducer for integration with the Redux store
export default authSlice.reducer;
