import { useForm } from "react-hook-form"; // Importing useForm from react-hook-form for form management
import * as yup from "yup"; // Importing yup for schema validation
import { yupResolver } from "@hookform/resolvers/yup"; // Importing yupResolver to integrate yup with react-hook-form
import "./popUp.css"; // Importing custom CSS
import { useEffect, useRef, useState } from "react";
import {
  clearTransactionDetails,
  createFuelTransaction,
  getTransactionList,
  getUserVehicle,
  hideAlert,
  showAlert,
} from "../../../features/redux-toolkit/transactionSlice";
import { useAppDispatch, useAppSelector } from "../../../features/hook";
import { createDecipheriv } from "crypto";
import { closeModal } from "../../../utils/modalUtils";
import { DisabledField } from "../../constant/fields/DisabledField";
import { formatDate } from "../../../utils/dateUtils";

/*
 * Name: AddFuelTransaction.tsx
 * Description: Component for adding a fuel transaction, including a form with fields for vehicle, fuel type, fuel retailer,
 * location, cost per liter, fuel quantity, total cost, and additional fields like GST and odometer with validation.
 * Version: 1.0.0
 */

// Define the form values interface as before
interface FormValues {
  vehicle: string;
  fuelType: string;
  fuelRetailer: string;
  location: string;
  costPerLitre: string;
  date: string;
  fuelQuantity: string;
  odometer?: string;
  totalCost: string;
  gst?: string;
  payUsing: string;
}

// Validation schema using yup
const schema = yup.object().shape({
  vehicle: yup.string().required("Vehicle is required"), // Vehicle is required
  fuelType: yup.string().required("Fuel type is required"), // Fuel type is required
  fuelRetailer: yup.string().required("Fuel retailer is required"), // Fuel retailer is required
  location: yup.string().required("Location is required"), // Location is required
  costPerLitre: yup
    .number()
    .typeError("Cost per liter should be a digit.")
    .required("Cost per liter is required")
    .positive("Cost per liter should be a positive digit.")
    .test(
      "maxDecimalPlaces",
      "Total cost should have at most two decimal places.",
      (value: any) => {
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ), // Cost per liter is required
  date: yup
    .date()
    .required("Date is required.")
    .typeError("Date is required.")
    .max(new Date(), "Date cannot be in the future")
    .test("valid-date", "Invalid date", (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  fuelQuantity: yup
    .number()
    .typeError("Total quantity should be a digit.")
    .required("Total quantity is required")
    .positive("Total quantity should be a positive digit.")
    .test(
      "maxDecimalPlaces",
      "Total quantity should have at most two decimal places.",
      (value: any) => {
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  odometer: yup.string().optional(), // Odometer is optional now
  // .test(
  //   "maxDecimalPlaces",
  //   "Odometer must have at most one decimal place",
  //   (value: any) => {
  //     // If value is undefined or null, skip the test
  //     if (value === undefined || value === null) return true;

  //     // Regex to match a positive number with at most one decimal place
  //     return !value || /^\d+(\.\d{1})?$/.test(value);
  //   }
  // ),
  totalCost: yup
    .number()
    .typeError("Total cost is should be a number")
    .required("Total cost is is required")
    .positive("Total cost is should be a positive number")
    .test(
      "maxDecimalPlaces",
      "Total cost should have at most two decimal places.",
      (value: any) => {
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ), // Total cost is required
  gst: yup.string().optional(), // GST is required
  isCalculateFuel: yup.boolean().optional(), // GST is required
  payUsing: yup.string().required("Paid Using is required"),
});

export const AddFuelTransaction = () => {
  const dispatch = useAppDispatch();
  const closeRef: any = useRef(null);
  const { userVehicle } = useAppSelector((state: any) => state.transaction);
  const { transactionDetails } = useAppSelector(
    (state: any) => state.transaction
  );
  const [currentDate, setCurrentDate] = useState<Date | null>(null); // Explicitly type the state
  console.log(transactionDetails, "transactionDetails");
  const [isCalculateFuel, setIsCalculateFuel] = useState(false); // Track "Calculate Fuel" toggle
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema), // Using yup for validation
  });

  const calculateFuel = () => {
    const costPerLitre = getValues("costPerLitre") || 0;
    const fuelQuantity = getValues("fuelQuantity") || 0;
    const totalCost = getValues("totalCost") || 0;

    const gstRate = 0.11; // GST rate (11%)
    let calculatedCostPerLitre = costPerLitre;
    let calculatedFuelQuantity = fuelQuantity;
    let calculatedTotalCost = totalCost;

    if (costPerLitre && fuelQuantity) {
      calculatedTotalCost = costPerLitre * fuelQuantity;
    } else if (costPerLitre && totalCost) {
      calculatedFuelQuantity = totalCost / costPerLitre;
    } else if (fuelQuantity && totalCost) {
      calculatedCostPerLitre = totalCost / fuelQuantity;
    } else {
      console.error("Insufficient data to perform calculations.");
      return;
    }

    const gst = (calculatedTotalCost * gstRate).toFixed(2);

    setValue("costPerLitre", calculatedCostPerLitre, { shouldValidate: true });
    setValue("fuelQuantity", calculatedFuelQuantity, { shouldValidate: true });
    setValue("totalCost", calculatedTotalCost, { shouldValidate: true });
    setValue("gst", gst, { shouldValidate: true });
  };

  const handleToggleCalculateFuel = () => {
    setIsCalculateFuel(!isCalculateFuel);
    if (!isCalculateFuel) calculateFuel(); // Trigger calculation when enabling
  };

  const handleTwoPrecision = (e: any, fieldName: keyof FormValues) => {
    let value = e.target.value;
    // If the value is empty, allow it (this allows the user to clear the field)
    if (value === "") {
      setValue(fieldName, value, { shouldValidate: true });
      return;
    }

    // Regular expression to allow up to two decimal places
    const regex = /^\d+(\.\d{0,2})?$/; // Matches integers or numbers with 1 or 2 decimal places

    // If the value matches the regex, we proceed; otherwise, we discard the input
    if (regex.test(value)) {
      // Set the value dynamically for any field using the fieldName which is typed as keyof FormValues
      setValue(fieldName, value, { shouldValidate: true });
    }
    if (isCalculateFuel) calculateFuel();
  };

  const handleOnePrecision = (e: any, fieldName: keyof FormValues) => {
    let value = e.target.value;

    // If the value is empty, allow it (this allows the user to clear the field)
    if (value === "") {
      setValue(fieldName, value, { shouldValidate: true });
      return;
    }
    // Regular expression to allow up to two decimal places
    const regex = /^\d+(\.\d{0,1})?$/; // Matches integers or numbers with 1 or 2 decimal places

    // If the value matches the regex, we proceed; otherwise, we discard the input
    if (regex.test(value)) {
      // Set the value dynamically for any field using the fieldName which is typed as keyof FormValues
      setValue(fieldName, value, { shouldValidate: true });
    }
  };

  const createdSuccessfully = (success: boolean) => {
    if (success) {
      closeRef.current.click();
      const successAlert = {
        icon: "success",
        title: "Created successfully",
        text: "The fuel transaction was created successfully.",
        confirmButtonText: "OK",
      };
      dispatch(showAlert(successAlert));
      reset();
      dispatch(getTransactionList());
      setIsCalculateFuel(false);
    }
  };

  const handleError = (success: boolean, error: string) => {
    if (!success) {
      closeRef.current.click();
      reset();
      setIsCalculateFuel(false);
      const errorAlert = {
        icon: "error",
        title: `${error}`,
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Cancel",
      };
      dispatch(showAlert(errorAlert));
    }
  };

  // Handle form submission
  const onSubmit = (data: any) => {
    dispatch(hideAlert());
    data.date = currentDate;
    console.log(data, "data in fuel transction");
    dispatch(createFuelTransaction(data)).then((res: any) => {
      console.log(res.payload.status, "resresresres");
      if (res.payload.status === 201 || res.payload.status === 200) {
        createdSuccessfully(res.payload.status);
      } else {
        handleError(res.payload.success, res.payload.error);
      }
    });
  };
  const onlyDigits = (e: any, fieldName: keyof FormValues) => {
    let check = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setValue(fieldName, check, {
      shouldValidate: true, // Trigger validation after setting the value
    });
  };

  useEffect(() => {
    dispatch(getUserVehicle());
  }, []);

  return (
    <>
      {/* Create fuel transaction modal */}
      <div
        className="modal fade"
        id="createFuelTransactionModal"
        aria-labelledby="createFuelTransactionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header px-5 py-4">
              <h5
                className="modal-title gradient-text"
                id="createFuelTransactionModalLabel"
              >
                {transactionDetails?.id ? (
                  <>Fuel Transaction ID: {transactionDetails.id} </>
                ) : (
                  <>Create Fuel Transaction</>
                )}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={() => {
                  dispatch(clearTransactionDetails());
                  closeModal("createFuelTransactionModal");
                  reset();
                  setIsCalculateFuel(false);
                }}
              ></button>
            </div>
            <div className="modal-body px-5 py-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row row-gap-4">
                  {/* Vehicle */}
                  <div className="col-md-6">
                    <label
                      htmlFor="vehicle"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Vehicle
                    </label>
                    {transactionDetails?.vehicle ? (
                      <>
                        <DisabledField
                          value={transactionDetails.vehicle || ""}
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="vehicle"
                          {...register("vehicle")} // Registering the input for form handling
                        >
                          <option value="">Select Vehicle</option>
                          {userVehicle &&
                            userVehicle.map((vehicle: any, index: number) => (
                              <option key={index} value={vehicle.value}>
                                {vehicle.registrationNumber}
                              </option>
                            ))}
                        </select>
                        {errors.vehicle && (
                          <p className="error-text">{errors.vehicle.message}</p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Fuel Type */}
                  <div className="col-md-6">
                    <label
                      htmlFor="fuel-type"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Fuel Type
                    </label>
                    {transactionDetails?.fuelType ? (
                      <>
                        <DisabledField
                          value={transactionDetails.fuelType || ""}
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="fuel-type"
                          {...register("fuelType")} // Registering the input for form handling
                        >
                          <option value="">Select Fuel Type</option>
                          <option value="petrol">Petrol</option>
                          <option value="diesel">Diesel</option>
                          <option value="electric">Electric</option>
                          <option value="unleaded">Unleaded</option>
                          <option value="regular_unleaded_petrol">
                            Regular unleaded petrol
                          </option>
                          <option value="unleaded_91">Unleaded 91</option>
                          <option value="e10">E10</option>
                          <option value="biodiesel">Biodiesel</option>
                          <option value="premium_unleaded_95">
                            Premium unleaded 95
                          </option>
                          <option value="e85">E85</option>
                          <option value="autogas">Autogas</option>
                          <option value="lpg">
                            Liquefied petroleum gas (LPG)
                          </option>
                          <option value="premium_unleaded_petrol">
                            Premium unleaded petrol
                          </option>
                          <option value="ethanol_blends">Ethanol blends</option>
                          <option value="ethanol_e10">Ethanol E10</option>
                          <option value="lpg_short">LPG</option>
                          <option value="premium_98_octane">
                            Premium 98-octane unleaded
                          </option>
                          <option value="shell_premium_98">
                            Shell premium unleaded 98
                          </option>
                          <option value="adblue">AdBlue</option>
                          <option value="premium_95">Premium 95</option>
                          <option value="ron">RON</option>
                          <option value="shell_diesel">Shell Diesel</option>
                          <option value="shell_unleaded_91">
                            Shell Unleaded 91
                          </option>
                          <option value="unleaded_98">Unleaded 98</option>
                        </select>
                        {errors.fuelType && (
                          <p className="error-text">
                            {errors.fuelType.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Cost Per Liter */}
                  <div className="col-md-6">
                    <label
                      htmlFor="costPerLitre"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Cost Per Litre (inc GST)
                    </label>

                    {transactionDetails?.costPerLitre ? (
                      <>
                        <DisabledField
                          value={transactionDetails.costPerLitre || ""}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          value={getValues("costPerLitre")}
                          className="form-control custom-input-popup"
                          id="costPerLitre"
                          placeholder="0"
                          onInput={(e: any) =>
                            handleTwoPrecision(e, "costPerLitre")
                          }
                          {...register("costPerLitre")} // Registering the input for form handling
                        />
                        {errors.costPerLitre && (
                          <p className="error-text">
                            {errors.costPerLitre.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Date */}
                  <div className="col-md-6">
                    <label
                      htmlFor="date"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Date
                    </label>
                    {transactionDetails?.date ? (
                      <>
                        <DisabledField
                          value={formatDate(transactionDetails.date) || ""}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="date"
                          className="form-control custom-input-popup date-input-color"
                          id="date"
                          {...register("date")} // Registering the input for form handling
                          onChange={(e) => {
                            const selectedDate = new Date(e.target.value);
                            if (!isNaN(selectedDate.getTime())) {
                              // Get the current time
                              const now = new Date();
                              selectedDate.setHours(
                                now.getHours(),
                                now.getMinutes(),
                                now.getSeconds(),
                                now.getMilliseconds()
                              );
                              setCurrentDate(selectedDate);
                              clearErrors("date"); // Clear the error message if the date is valid
                            }
                          }}
                        />
                        {errors.date && (
                          <p className="error-text">{errors.date.message}</p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Fuel Quantity */}
                  <div className="col-md-6">
                    <label
                      htmlFor="total-quantity"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Fuel Quantity (L)
                    </label>
                    {transactionDetails?.fuelQuantity ? (
                      <>
                        <DisabledField
                          value={transactionDetails.fuelQuantity || ""}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          value={getValues("fuelQuantity")}
                          className="form-control custom-input-popup"
                          id="fuelQuantity"
                          placeholder="0"
                          onInput={(e: any) =>
                            handleTwoPrecision(e, "fuelQuantity")
                          }
                          {...register("fuelQuantity")} // Registering the input for form handling
                        />

                        {errors.fuelQuantity && (
                          <p className="error-text">
                            {errors.fuelQuantity.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Calculate Fuel */}
                  {!transactionDetails?.id && (
                    <div className="col-md-6">
                      <label
                        htmlFor="total-quantity"
                        className="col-form-label custom-label-popup text-white"
                      >
                        {"l "}
                      </label>
                      <div className="d-flex justify-content-between align-items-center form-control custom-input-popup">
                        <label
                          className="form-check-label mb-0 custom-label-popup"
                          htmlFor="calculateFuel"
                        >
                          Calculate Fuel
                        </label>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="calculateFuel"
                            checked={isCalculateFuel}
                            onChange={handleToggleCalculateFuel}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Total Cost */}
                  <div className="col-md-6">
                    <label
                      htmlFor="totalCost"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Total Cost (inc GST)
                    </label>
                    {transactionDetails?.totalCost ? (
                      <>
                        <DisabledField
                          value={transactionDetails.totalCost || ""}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          id="total-cost"
                          value={getValues("totalCost")}
                          className="form-control custom-input-popup"
                          placeholder="0"
                          onInput={(e: any) => {
                            handleTwoPrecision(e, "totalCost");
                            // handleDeleteTotalCost();
                          }}
                          {...register("totalCost")} // Registering the input for form handling
                        />
                        {errors.totalCost && (
                          <p className="error-text">
                            {errors.totalCost.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* GST */}
                  <div className="col-md-6">
                    <label
                      htmlFor="gst"
                      className="col-form-label custom-label-popup"
                    >
                      GST
                    </label>
                    {transactionDetails?.gst ? (
                      <>
                        <DisabledField value={transactionDetails.gst || ""} />
                      </>
                    ) : (
                      <>
                        <input
                          className="form-control custom-input-popup"
                          id="gst"
                          value={getValues("gst")}
                          disabled
                          placeholder="GST"
                          {...register("gst")} // Registering the input for form handling
                        />
                      </>
                    )}
                  </div>

                  {/* Odometer */}
                  <div className="col-md-6">
                    <label
                      htmlFor="odometer"
                      className="col-form-label custom-label-popup"
                    >
                      Odometer
                    </label>
                    {transactionDetails?.id ? (
                      <>
                        <DisabledField
                          value={transactionDetails.odometer || ""}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          className="form-control custom-input-popup"
                          id="odometer"
                          value={getValues("odometer")}
                          onInput={(e: any) => onlyDigits(e, "odometer")}
                          placeholder="Enter Odometer"
                          {...register("odometer")} // Registering the input for form handling
                        />
                        {errors.odometer && (
                          <p className="error-text">
                            {errors.odometer.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Upload Receipt */}
                  <div className="col-md-6">
                    <label
                      // htmlFor="receipt-file"
                      className="col-form-label custom-label-popup"
                    >
                      Upload Receipt
                    </label>{" "}
                    {transactionDetails?.id ? (
                      <>
                        <DisabledField
                          value={
                            transactionDetails?.receiptFile || "Not Available"
                          }
                        />
                      </>
                    ) : (
                      <div className="d-flex">
                        <input
                          type="file"
                          className="form-control custom-input-popup centered-file-input"
                          id="receipt-file"
                        />
                      </div>
                    )}
                  </div>

                  {/* Fuel Retailer */}
                  <div className="col-md-6">
                    <label
                      htmlFor="fuel-retailer"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Fuel Retailer
                    </label>
                    {transactionDetails?.fuelRetailer ? (
                      <>
                        <DisabledField
                          value={transactionDetails.fuelRetailer || ""}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          className="form-control custom-input-popup"
                          id="fuel-retailer"
                          placeholder="Enter Fuel Retailer"
                          {...register("fuelRetailer")} // Registering the input for form handling
                        />
                        {errors.fuelRetailer && (
                          <p className="error-text">
                            {errors.fuelRetailer.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Location */}
                  <div className="col-md-6">
                    <label
                      htmlFor="location"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Location
                    </label>
                    {transactionDetails?.location ? (
                      <>
                        <DisabledField
                          value={transactionDetails.location || ""}
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="location"
                          {...register("location")} // Registering the input for form handling
                        >
                          <option value="">Select Location</option>
                          <option value="New South Wales">
                            New South Wales
                          </option>
                          <option value="Victoria">Victoria</option>
                          <option value="Queensland">Queensland</option>
                          <option value="Western Australia">
                            Western Australia
                          </option>
                          <option value="South Australia">
                            South Australia
                          </option>
                          <option value="Tasmania">Tasmania</option>
                          <option value="Australian Capital Territory">
                            Australian Capital Territory
                          </option>
                          <option value="Northern Territory">
                            Northern Territory
                          </option>
                          {/* Add your location options here */}
                        </select>
                        {errors.location && (
                          <p className="error-text">
                            {errors.location.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Paid Using */}
                  <div className="col-md-6">
                    <label
                      htmlFor="payUsing"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Paid Using
                    </label>
                    {transactionDetails?.payUsing ? (
                      <>
                        <DisabledField
                          value={transactionDetails.payUsing || ""}
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="payUsing"
                          {...register("payUsing")}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option>Cash</option>
                          <option>Card</option>
                          <option>Visa</option>
                        </select>
                        {errors.payUsing && (
                          <p className="error-text">
                            {errors.payUsing.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {transactionDetails?.id ? (
                  <>
                    <div className="modal-footer p-0 pt-4 pb-4">
                      <div
                        className="custom-gradient-btn text-center d-flex align-items-center"
                        onClick={() => {
                          dispatch(clearTransactionDetails());
                          closeModal("createFuelTransactionModal");
                          reset();
                        }}
                        style={{
                          padding: "7px 56px",
                          backgroundColor: "#00429B",
                          border: "none",
                          height: "44px",
                        }}
                      >
                        Close
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="modal-footer p-0 pt-4 pb-4">
                      <button
                        type="submit"
                        className="custom-gradient-btn"
                        style={{
                          padding: "7px 56px",
                          backgroundColor: "#00429B",
                          border: "none",
                          height: "44px",
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
