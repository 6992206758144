import React from "react";
import Navbar from "./Navbar";
import SystemSetUp from "../popUp/SystemSetUp";
import { useAppSelector } from "../../features/hook"; // Importing custom hook to select state from Redux tollkit
import { useAuth0 } from "@auth0/auth0-react"; // Importing Auth0 for authentication
import DataTable from "../data-table/DataTable";
import { IncomeExpenseOverView } from "../income-expense-overView/IncomeExpenseOverView";

/*
 * Name: HomePage.tsx
 * Description: Main page component that renders the navbar or setup form based on authentication status.
 * Version: 1.0.0
 */

export const HomePage: React.FC = () => {
  // Retrieving authentication state from Redux
  const { validAuthZero } = useAppSelector((state: any) => state.transaction);
  const { userIsRegister } = useAppSelector((state: any) => state.auth);
  // const { user, isAuthenticated, isLoading } = useAuth0(); // Using Auth0 hook for user authentication

  return (
    <>
      {/* Conditionally rendering the SystemSetUp or Navbar based on authentication status */}
      {/* {!validAuthZero ||
      localStorage.getItem("systemFormStatus") === "false" ? ( */}

      {/* localStorage?.getItem("systemFormStatus") === "true" || validAuthZero */}
      {localStorage?.getItem("systemFormStatus") === "true" || validAuthZero ? (
        <>
          <div className="bg-image-white d-block">
            <Navbar /> {/* Render the Navbar if validation is successful */}
            <IncomeExpenseOverView />
            <DataTable />
            {/* Render the system setup form if validation fails or the status is false */}
          </div>
        </>
      ) : (
        <>
          <SystemSetUp />
        </>
      )}
    </>
  );
};
