export const openModal = (modalId: string) => {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      modalElement.classList.add("show");
      modalElement.style.display = "block";
      modalElement.style.backgroundColor="rgb(127 127 127 / 42%)"
    }
  };
  
  export const closeModal = (modalId: string) => {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      modalElement.classList.remove("show");
      modalElement.style.display = "none";
    }
  };
  