import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./app/assets/css/common.css";
import "./app/assets/css/mediaQuery.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/features/store";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { PrimeReactProvider } from "primereact/api";

// Importing favicon in React should be done in the index.html file
<link rel="icon" href="favicon.ico" />;

ReactDOM.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <Auth0Provider
        domain="dev-0dvinvvfjvryfxiw.us.auth0.com"
        clientId="641vMa5iLfidmKDYiS9MZ4NXSaxGYsuE"
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </Auth0Provider>
    </PrimeReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
