import { configureStore } from '@reduxjs/toolkit'; // Importing configureStore from Redux Toolkit for setting up the Redux store

import transactionReducer from './redux-toolkit/transactionSlice'; // Importing the transaction slice reducer for handling transactions
import authReducer from './redux-toolkit/authSlice'; // Importing the transaction slice reducer for handling transactions

/*
 * Name: store.ts
 * Description: Configuration of the Redux store for the application, incorporating slices for state management.
 * Version: 1.2.0
 */

export const store = configureStore({
  reducer: {
    auth: authReducer,
    transaction: transactionReducer, // Adding the transaction slice reducer to the Redux store to manage transaction-related state
    // Additional reducers can be added here as needed to manage other pieces of state
  },
});

// Type definition for the root state of the Redux store
export type RootState = ReturnType<typeof store.getState>; // Extracts the state type from the store using `getState`

// Type definition for the app's dispatch function, useful for typing dispatch calls
export type AppDispatch = typeof store.dispatch; // Provides the type for dispatch function from the store
