import axios from "axios"; // Importing axios for making HTTP requests

/*
 * Name: AxiosService.ts
 * Description: Configured Axios instance for API requests with interceptors for handling tokens and responses.
 * Version: 1.0.0
 */

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Setting the base URL from environment variables
});

// Add a request interceptor
Api.interceptors.request.use(
  (config: any) => {
    // const token = localStorage.getItem("_auth0Token"); // Retrieving the token from localStorage
    const token = localStorage.getItem("_auth0Token"); // Retrieving the token from localStorage

    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // Adding the token to the request headers if it exists
    }
    return config; // Returning the modified config
  },
  (error: any) => {
    return Promise.reject(error); // Rejecting the promise if there's an error
  }
);

// Add a response interceptor
Api.interceptors.response.use(
  (response: any) => {
    return response; // Returning the response as is if no errors
  },
  function (error: any) {
    console.log(error, "error");

    // Handling 400 Bad Request error for specific API call
    if (error.response && error.response.status === 400) {
      console.error("Bad Request (400):", error.response.data); // Log the error data

      // You can show a message to the user or handle it in some other way
      // For example, if you are using a toast library, you could do something like:
      // toast.error("Bad Request. Please check your inputs.");

      // If you need to return a specific result or value, you can reject the promise or resolve it with some fallback.
      return Promise.reject(error.response.data); // Reject the promise with error data
    }

    // Handling other errors like 500 or 404 could be done here too
    // You can customize the error handling logic based on the status code
    if (error.response && error.response.status === 500) {
      console.error("Server error (500):", error.response.data);
    }

    const originalRequest = error.config; // Storing the original request config

    // Handling 401 Unauthorized error for specific API call
    if (
      error.response.status === 401
      //  &&
      // originalRequest.url ===
      //   `${process.env.REACT_APP_API_URL}/authentication_tokens/create`
    ) {
      window.location.href = "/"; // Redirecting to login if authentication fails
      return Promise.reject(error);
    }

    // Handling retry logic for 401 Unauthorized errors
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true; // Marking the request as retried

    //   // Removing the token and redirecting to login
    //   if (localStorage.getItem("_auth0Token")) {
    //     localStorage.removeItem("_auth0Token");
    //     window.location.href = "/";
    //   } else {
    //     window.location.href = "/login"; // Redirecting to login if token is missing
    //     localStorage.removeItem("_auth0Token");
    //   }
    //   return axios(originalRequest).then((res: any) => {}); // Retrying the original request
    // }

    // If you don't handle other error codes specifically, you can reject the promise as usual
    return Promise.reject(error); // Rejecting the promise for any other errors
  }
);

export default Api; // Exporting the configured Axios instance
