import { Link } from "react-router-dom"; // Importing Link for navigation
import "./pageNotFound.css"; // Importing CSS for styling

/*
 * Name: PageNotFound.tsx
 * Description: Component that displays a 404 error page when a user navigates to a non-existent route.
 * Version: 1.0.0
 */

export const PageNotFound = () => {
  return (
    <>
      <div className="page-not-container">
        <div className="center">
          <div className="error">
            <div className="number">4</div>{" "}
            {/* Displaying part of the 404 error */}
            <div className="illustration">
              {" "}
              {/* Illustration section */}
              <div className="circle"></div> {/* Decorative circle */}
              <div className="clip">
                {" "}
                {/* Container for the animated face */}
                <div className="paper">
                  <div className="face">
                    <div className="eyes">
                      {" "}
                      {/* Eyes section */}
                      <div className="eye eye-left"></div> {/* Left eye */}
                      <div className="eye eye-right"></div> {/* Right eye */}
                    </div>
                    <div className="rosyCheeks rosyCheeks-left"></div>{" "}
                    {/* Left rosy cheek */}
                    <div className="rosyCheeks rosyCheeks-right"></div>{" "}
                    {/* Right rosy cheek */}
                    <div className="mouth"></div> {/* Mouth section */}
                  </div>
                </div>
              </div>
            </div>
            <div className="number">4</div>{" "}
            {/* Displaying the other part of the 404 error */}
          </div>

          <div className="text">
            Oops. The page you're looking for doesn't exist.{" "}
            {/* Error message */}
          </div>

          <Link to="/" className="button">
            {" "}
            {/* Link to navigate back to the login page */}
            Back Login
          </Link>

          <div className="by">
            {" "}
            {/* Attribution for the illustration */}
            Illustration "Cezan - 404 Page Not Found" by
            <a
              className="byLink"
              href="https://dribbble.com/shots/3965778-Cezan-404-Page-Not-Found"
              target="_blank" // Opens link in a new tab
              rel="noopener noreferrer" // Security feature for external links
            >
              Masoud Ardestani
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
