import { useForm } from "react-hook-form"; // Importing useForm from react-hook-form for form management
import * as yup from "yup"; // Importing yup for schema validation
import { yupResolver } from "@hookform/resolvers/yup"; // Importing yupResolver to integrate yup with react-hook-form
import "./systemSetUp.css";
import { useNavigate } from "react-router-dom"; // Importing useNavigate for navigation
import { useDispatch } from "react-redux"; // Importing useDispatch for Redux actions
// Importing the Redux action for authentication validation
import { useAppDispatch, useAppSelector } from "../../features/hook"; // Importing custom hook to select state from Redux
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { systemValidAuthZero } from "../../features/redux-toolkit/transactionSlice";
import { addUserDetails } from "../../features/redux-toolkit/authSlice";

/*
 * Name: SystemSetUp.tsx
 * description: Component for setting up system configurations with form validation.
 * Version: 1.0.0
 */

// Form values interface matching the schema
interface FormValues {
  businessName: string;
  emailId?: string;
  abn: string;
  acn?: string;
  phoneNumber: string;
  address: {
    streetAddress?: string;
    poBoxAddress?: string;
    suburb: string;
    postcode: string;
    state: string;
  };
  vehicleDetails: Array<{
    registrationNo: string;
    description: string;
  }>;
  vehicleDetails2: Array<{
    registrationNo?: string;
    description?: string;
  }>;
}

// Validation schema using yup
const schema = yup.object().shape({
  abn: yup
    .string()
    .required("ABN is required")
    .length(11, "ABN should be 11 digits."),
  acn: yup
    .string()
    .optional()
    .test("is-valid-acn", "ACN should be 9 digits.", (value) => {
      return !value || value.length === 9;
    }),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Invalid Phone No."),
  businessName: yup
    .string()
    .required("Business Name is required")
    .min(2, "Business Name should be at least 2 characters."),
  emailId: yup.string().optional(),
  address: yup.object({
    streetAddress: yup.string().optional(),
    poBoxAddress: yup.string().optional(),
    suburb: yup.string().required("Suburb or locality is required"),
    postcode: yup
      .string()
      .required("Postcode is required")
      .matches(/^[0-9]{4}$/, "Postcode should be a valid 4-digit number."),
    state: yup.string().required("State is required"),
  }),
  vehicleDetails: yup
    .array()
    .of(
      yup.object().shape({
        registrationNo: yup
          .string()
          .required("Registration Number is required.")
          .min(6, "Registration Number should be at least 6 characters.")
          .max(17, "Registration Number should be no more than 17 characters."),

        description: yup.string().required("description is required"),
      })
    )
    .default([]),
  vehicleDetails2: yup
    .array()
    .of(
      yup.object().shape({
        registrationNo: yup.string().optional(),
        description: yup.string().optional(),
      })
    )
    .default([]),
});

const SystemSetUp = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { validAuthZero } = useAppSelector((state: any) => state.transaction);
  const [vehicleCount, setVehicleCount] = useState<number | "">("");
  const authEmailId = localStorage.getItem("email");

  const handleCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const count = e.target.value === "" ? "" : parseInt(e.target.value);
    setVehicleCount(count);
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      vehicleDetails: [], // Initialize vehicleDetails as an empty array
      vehicleDetails2: [], // Initialize vehicleDetails2 as an empty array
    },
  });

  const onSubmit = (data: FormValues) => {
    // Filter out incomplete objects from vehicleDetails2
    const validVehicleDetails2 = (data.vehicleDetails2 ?? []).filter(
      (item) => item.registrationNo && item.description
    ) as { registrationNo: string; description: string }[];

    // Combine vehicleDetails and valid items from vehicleDetails2
    const combinedVehicleDetails = [
      ...data.vehicleDetails,
      ...validVehicleDetails2,
    ];

    // Assign combined vehicle details to vehicleDetails
    data.vehicleDetails = combinedVehicleDetails;
    data.vehicleDetails2 = [];
    data.emailId = authEmailId || user?.email;
    console.log(data, "system");
    dispatch(addUserDetails(data)).then((res: any) => {
      console.log(res, "ress");
      if (res.payload.success || res.payload.abn) {
        reset();
        dispatch(systemValidAuthZero());
        navigate("/");
      }
    });
  };

  const onlyDigits = (e: any, fieldName: keyof FormValues) => {
    let check = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setValue(fieldName, check, {
      shouldValidate: true, // Trigger validation after setting the value
    });
  };

  return (
    <>
      <div className="bg-image"></div>
      <div
        className="modal fade show"
        id="incomeExpenseModal1"
        aria-labelledby="incomeExpenseModal1"
        style={{ display: "block" }}
        aria-hidden="true"
      >
        <div className="modal-dialog systemSetUp-form">
          <div className="modal-content" style={{ background: "transparent" }}>
            <div className="modal-header px-5 py-4 justify-content-center">
              <h1 className="modal-title gradient-text" id="addIncomeExpense">
                System Setup
              </h1>
            </div>
            <div className="modal-body px-5 px-sm-0 px-md-0 py-5">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                      {/* Business Name */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="businessName"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Business Name
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="businessName"
                          placeholder="Enter Business Name"
                          {...register("businessName")}
                        />
                        {errors.businessName && (
                          <p className="error error-text">
                            {errors.businessName.message}
                          </p>
                        )}
                      </div>

                      {/* Email Address */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="emailId"
                          className="col-form-label custom-label-popup"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control custom-input-popup"
                          id="emailId"
                          value={authEmailId || ""}
                          disabled
                          placeholder="Enter Email Address"
                          {...register("emailId")}
                        />
                      </div>

                      {/* ABN */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="abn"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          ABN
                        </label>
                        <input
                          minLength={11}
                          maxLength={11}
                          placeholder="Enter ABN"
                          className="form-control custom-input-popup"
                          value={getValues("abn")}
                          onInput={(e: any) => onlyDigits(e, "abn")}
                          {...register("abn")}
                        />
                        {errors.abn && (
                          <p className="error error-text">
                            {errors.abn.message}
                          </p>
                        )}
                      </div>

                      {/* ACN */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="acn"
                          className="col-form-label custom-label-popup"
                        >
                          ACN
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="acn"
                          placeholder="Enter ACN"
                          value={getValues("acn")}
                          maxLength={9}
                          onInput={(e: any) => onlyDigits(e, "acn")}
                          {...register("acn")}
                        />
                        {errors.acn && (
                          <p className="error error-text">
                            {errors.acn.message}
                          </p>
                        )}
                      </div>

                      {/* Office Phone Number */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="phoneNumber"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Office Phone Number
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          minLength={10}
                          maxLength={10}
                          value={getValues("phoneNumber")}
                          onInput={(e: any) => onlyDigits(e, "phoneNumber")}
                          placeholder="Enter Office Phone Number"
                          {...register("phoneNumber")}
                        />
                        {errors.phoneNumber && (
                          <p className="error error-text">
                            {errors.phoneNumber.message}
                          </p>
                        )}
                      </div>
                      <div className="col-md-5"></div>
                    </div>
                    <div className="col-12 d-flex justify-content-center pt-3">
                      <hr className="custom-hr" />
                    </div>

                    <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                      {/* Street Address */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.streetAddress"
                          className="col-form-label custom-label-popup"
                        >
                          Street Address
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="address.streetAddress"
                          placeholder="Enter Street Address"
                          {...register("address.streetAddress")}
                        />
                        {errors.address?.streetAddress && (
                          <p className="error error-text">
                            {errors.address.streetAddress.message}
                          </p>
                        )}
                      </div>

                      {/* Postbox Code */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.poBoxAddress"
                          className="col-form-label custom-label-popup"
                        >
                          PO Box Address
                        </label>
                        <input
                          type="text"
                          className="form-control custom-input-popup"
                          id="address.poBoxAddress"
                          placeholder="Enter PO box address"
                          {...register("address.poBoxAddress")}
                        />
                        {errors.address?.poBoxAddress && (
                          <p className="error error-text">
                            {errors.address?.poBoxAddress.message}
                          </p>
                        )}
                      </div>

                      {/* Suburb or Locality */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.suburb"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Suburb or Locality
                        </label>
                        <select
                          className="form-select custom-select-popup"
                          id="address.suburb"
                          // value={getValues("suburbOrLocatily")}
                          {...register("address.suburb")}
                        >
                          <option value="">Select Suburb or Locality</option>
                          <option value="NotAvailable">Not Available</option>
                        </select>
                        {errors.address?.suburb && (
                          <p className="error error-text">
                            {errors.address.suburb.message}
                          </p>
                        )}
                      </div>

                      {/* Postcode */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.postcode"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Postcode
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          minLength={4}
                          maxLength={4}
                          id="address.postcode"
                          placeholder="Enter Postcode"
                          onInput={(e: any) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            e.target.value = value; // Update the input value directly
                          }}
                          {...register("address.postcode")}
                        />
                        {errors.address?.postcode && (
                          <p className="error error-text">
                            {errors.address.postcode.message}
                          </p>
                        )}
                      </div>

                      {/* State */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.state"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          State
                        </label>
                        <select
                          className="form-select custom-select-popup"
                          id="address.state"
                          {...register("address.state")}
                        >
                          <option value="">Select State</option>
                          <option value="New South Wales">
                            New South Wales
                          </option>
                          <option value="Victoria">Victoria</option>
                          <option value="Queensland">Queensland</option>
                          <option value="Western Australia">
                            Western Australia
                          </option>
                          <option value="South Australia">
                            South Australia
                          </option>
                          <option value="Tasmania">Tasmania</option>
                          <option value="Australian Capital Territory">
                            Australian Capital Territory
                          </option>
                          <option value="Northern Territory">
                            Northern Territory
                          </option>
                        </select>
                        {errors.address?.state && (
                          <p className="error error-text">
                            {errors.address.state.message}
                          </p>
                        )}
                      </div>
                      <div className="col-md-5"></div>
                    </div>

                    <div className="col-12 d-flex justify-content-center pt-3">
                      <hr className="custom-hr" />
                    </div>

                    {/*  vehicle details*/}
                    <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                      <div className="col-12 col-md-5 mb-3">
                        <label
                          htmlFor="registrationNo1"
                          className="col-form-label custom-label-title  "
                        >
                          Vehicle Details
                        </label>
                      </div>
                      <div className="col-12 col-md-5"></div>
                    </div>

                    <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                      {/* Registration No  */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="registrationNo1"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Registration No
                        </label>

                        <input
                          className="form-control custom-input-popup"
                          minLength={6}
                          maxLength={17}
                          id="vehicleDetails[0].registrationNo1"
                          placeholder="Enter Registration Number"
                          {...register("vehicleDetails.0.registrationNo")}
                        />
                        {errors.vehicleDetails?.[0]?.registrationNo && (
                          <p className="error error-text">
                            {errors.vehicleDetails[0].registrationNo.message}
                          </p>
                        )}
                      </div>

                      {/* Description1 */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="Description1"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Description
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="Description1"
                          placeholder="Enter Description"
                          {...register("vehicleDetails.0.description")}
                        />
                        {errors.vehicleDetails?.[0]?.description && (
                          <p className="error error-text">
                            {errors.vehicleDetails?.[0]?.description.message}
                          </p>
                        )}
                      </div>

                      {/* Registration No 2 */}

                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="registrationNo2"
                          className="col-form-label custom-label-popup"
                        >
                          Registration No
                        </label>

                        <input
                          className="form-control custom-input-popup"
                          id="registrationNo2"
                          minLength={6}
                          maxLength={17}
                          placeholder="Enter Registration Number"
                          {...register("vehicleDetails2.0.registrationNo")}
                        />
                        {errors.vehicleDetails2?.[0]?.registrationNo && (
                          <p className="error error-text">
                            {
                              errors.vehicleDetails2?.[0]?.registrationNo
                                .message
                            }
                          </p>
                        )}
                      </div>

                      {/* Description2 */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="Description2"
                          className="col-form-label custom-label-popup"
                        >
                          Description
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="Description2"
                          placeholder="Enter Description"
                          {...register("vehicleDetails2.0.description")}
                        />
                        {errors.vehicleDetails2?.[0]?.description && (
                          <p className="error error-text">
                            {errors.vehicleDetails2?.[0]?.description.message}
                          </p>
                        )}
                      </div>

                      <div className="col-12 d-flex justify-content-center pt-3">
                        <hr className="custom-hr" />
                      </div>

                      <div className="col-12 d-flex justify-content-center ">
                        <button type="submit" className="submit-btn">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemSetUp;
