import "./popUp.css"; // Importing custom CSS for styling the user details modal
import userImg from "../../../assets/temp-image/userImage.svg"; // Importing a user profile image
import { useEffect, useRef, useState } from "react"; // Importing React hooks for state management and side effects
import plusIcon from "../../../assets/icons/Plus-Icon.svg";
import * as yup from "yup"; // Import Yup for form validation schema
import { yupResolver } from "@hookform/resolvers/yup"; // Resolver to connect Yup schema to react-hook-form
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../features/hook";
import {
  addVehicleDetails,
  getUserDetails,
  getUserVehicle,
  hideAlert,
  showAlert,
} from "../../../features/redux-toolkit/transactionSlice";

interface VehicleInfo {
  registrationNo: string;
  description: string;
}
interface VehicleInfoAPI {
  registrationNo: string;
  description: string;
}
type VehicleDetailsPayload = {
  vehicleDetails: VehicleInfoAPI[];
};

// Validation schema for the form using Yup
const schema = yup.object().shape({
  registrationNo: yup.string().required("Registration Number is required."),
  description: yup.string().required("Description is required"),
});

export const UserDetails = () => {
  const [isAddVehicleClicked, setIsAddVehicleClicked] = useState(false);
  const dispatch = useAppDispatch(); // Get dispatch function from custom hook
  const { profileInfo } =
    useAppSelector((state: any) => state.transaction) || false; // Access categories from the store
  const closeRef: any = useRef(null);
  // Use the form hook for handling form state and validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema), // Apply Yup validation schema
  });

  const addVehicle = () => {
    setIsAddVehicleClicked(true); // Open the form and disable the Add Vehicle button
  };

  const createdSuccessfully = (success: boolean) => {
    if (success) {
      // closeRef.current.click();
      const successAlert = {
        icon: "success",
        title: "Added successfully",
        text: "The Vehicle was Added successfully.",
        confirmButtonText: "OK",
      };
      dispatch(showAlert(successAlert));
      reset();
      dispatch(getUserDetails());
      setIsAddVehicleClicked(false);
    }
  };

  const handleError = (success: boolean, error: string) => {
    if (!success) {
      closeRef.current.click();
      reset();
      setIsAddVehicleClicked(false);
      const errorAlert = {
        icon: "error",
        title: `${error}`,
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Cancel",
      };
      dispatch(showAlert(errorAlert));
    }
  };

  const onSubmit = (data: VehicleInfoAPI) => {
    dispatch(hideAlert());
    const vehicleDetails: VehicleDetailsPayload = { vehicleDetails: [data] };
    dispatch(addVehicleDetails(vehicleDetails)).then((res: any) => {
      console.log(res.payload.status, "resresresres");
      if (res.payload.status === 201 || res.payload.status === 200) {
        createdSuccessfully(res.payload.status);
        dispatch(getUserVehicle());
      } else {
        handleError(res.payload.success, res.payload.error);
      }
    });
  };

  const {
    abn,
    acn,
    businessName,
    phoneNumber,
    userAddress,
    userId,
    vehicleDetails,
  } = profileInfo || {};
  // useEffect hook to fetch user profile when the component mounts
  useEffect(() => {
    dispatch(getUserDetails()); // Fetching the profile data on mount
  }, []); // Empty dependency array means this effect runs only once on mount

  return (
    <>
      {/* Modal for displaying user details */}
      <div
        className="modal fade"
        id="userDetailsModal"
        aria-labelledby="userDetailsModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header px-5 py-4 ml-1 align-items-start">
              <div className="d-flex align-items-center column-gap-3">
                <div className="profile-img-container">
                  <img src={userImg} alt="profile-img" />
                </div>
                <div className="d-flex flex-column">
                  <div className="profile-name">John doe</div>
                  <div className="profile-email">{userId?.emailid || ""}</div>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                ref={closeRef}
                onClick={() => {
                  setIsAddVehicleClicked(false);
                  reset();
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <hr className="border-top-only" /> */}
            <div className="modal-body px-5 px-sm-0 px-md-0 py-5">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                    {/* Business Name */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="businessName"
                        className="col-form-label custom-label-popup"
                      >
                        Business Name
                      </label>
                      <div className="disable-profile-input">
                        {businessName || ""}
                      </div>
                    </div>

                    {/* Email Address */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="emailAddress"
                        className="col-form-label custom-label-popup"
                      >
                        Email Address
                      </label>
                      <div className="disable-profile-input">
                        {userId?.emailid}
                      </div>
                    </div>

                    {/* ABN */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="ABN"
                        className="col-form-label custom-label-popup"
                      >
                        ABN
                      </label>
                      <div className="disable-profile-input">{abn || ""}</div>
                    </div>

                    {/* ACN */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="ACN"
                        className="col-form-label custom-label-popup"
                      >
                        ACN
                      </label>
                      <div className="disable-profile-input">{acn || ""}</div>
                    </div>

                    {/* Office Phone Number */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="costPerLiter"
                        className="col-form-label custom-label-popup"
                      >
                        Office Phone Number
                      </label>
                      <div className="disable-profile-input">
                        {phoneNumber || ""}
                      </div>
                    </div>
                    <div className="col-md-5"></div>
                  </div>
                  <div className="col-12 d-flex justify-content-center pb-3">
                    <hr className="custom-hr" />
                  </div>

                  <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                    {/* Street Address */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="streetAddress"
                        className="col-form-label custom-label-popup"
                      >
                        Street Address
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.streetAddress || ""}
                      </div>
                    </div>

                    {/* Postbox Code */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="postboxCode"
                        className="col-form-label custom-label-popup"
                      >
                        PO Box Address
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.poBoxAddress || ""}
                      </div>
                    </div>

                    {/* Suburb or Locality */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="suburbOrLocatily"
                        className="col-form-label custom-label-popup"
                      >
                        Suburb or Locality
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.suburb || ""}
                      </div>
                    </div>

                    {/* Postcode */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="postCodeNum"
                        className="col-form-label custom-label-popup"
                      >
                        Postcode
                      </label>
                      <div className="disable-profile-input">
                        {" "}
                        {userAddress?.postCode || ""}
                      </div>
                    </div>

                    {/* State */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="state"
                        className="col-form-label custom-label-popup"
                      >
                        State
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.state || ""}
                      </div>
                    </div>
                    <div className="col-md-5"></div>
                  </div>

                  <div className="col-12 d-flex justify-content-center pt-3">
                    <hr className="custom-hr" />
                  </div>

                  {/*  vehicle details*/}
                  <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                    <div className="col-12 col-md-5 d-flex align-items-center">
                      <label
                        htmlFor="registrationNo1"
                        className="col-form-label custom-label-title  "
                      >
                        Vehicle Details
                      </label>
                    </div>
                    <div className="col-12 col-md-5 d-flex justify-content-end">
                      <button
                        onClick={addVehicle}
                        className={
                          isAddVehicleClicked
                            ? "custom-gradient-btn opacity-70 default"
                            : "custom-gradient-btn "
                        }
                        disabled={isAddVehicleClicked}
                      >
                        <img src={plusIcon} alt="" /> Add Vehicle
                      </button>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                    {vehicleDetails && vehicleDetails.length > 0 ? (
                      vehicleDetails.map((e: any, index: any) => (
                        <>
                          <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                            <div key={index} className="col-12 col-md-5">
                              {/* Registration No Input */}
                              <label
                                htmlFor={`registrationNumber${index}`}
                                className="col-form-label custom-label-popup"
                              >
                                Registration No
                              </label>
                              <input
                                className="disable-profile-input"
                                minLength={6}
                                maxLength={17}
                                id={`registrationNumber${index}`}
                                name={`registrationNumber${index}`}
                                defaultValue={e.registrationNumber}
                              />
                            </div>
                            <div key={index} className="col-12 col-md-5">
                              {/* Description Input */}
                              <label
                                htmlFor={`description${index}`}
                                className="col-form-label custom-label-popup mt-3"
                              >
                                Description
                              </label>
                              <input
                                className="disable-profile-input"
                                id={`description${index}`}
                                name={`description${index}`}
                                defaultValue={e.description}
                              />
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="col-12 d-flex justify-content-center">
                        <p>No vehicles available</p>
                      </div>
                    )}
                  </div>
                  {/* Add Vehicle Form */}
                  {isAddVehicleClicked && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                        <div className="col-12 col-md-5">
                          <label
                            htmlFor="registrationNo"
                            className="col-form-label custom-label-popup mandatory"
                          >
                            Registration No
                          </label>
                          <input
                            className="form-control custom-input-popup"
                            minLength={6}
                            maxLength={17}
                            id="registrationNo"
                            placeholder="Enter Registration Number"
                            {...register("registrationNo")}
                          />
                          {errors.registrationNo && (
                            <p className="error error-text">
                              {errors.registrationNo.message}
                            </p>
                          )}
                        </div>

                        <div className="col-12 col-md-5">
                          <label
                            htmlFor="description"
                            className="col-form-label custom-label-popup mandatory"
                          >
                            Description
                          </label>
                          <input
                            className="form-control custom-input-popup"
                            id="description"
                            placeholder="Enter Description"
                            {...register("description")}
                          />
                          {errors.description && (
                            <p className="error error-text">
                              {errors.description.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-12 d-flex justify-content-center pt-3">
                        <hr className="custom-hr" />
                      </div>

                      <div className="col-12 d-flex justify-content-center ">
                        <button type="submit" className="submit-btn">
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
