import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./popUp.css";
import { useAppDispatch, useAppSelector } from "../../../features/hook";
import {
  addTransaction,
  clearTransactionDetails,
  getTransactionList,
  hideAlert,
  showAlert,
} from "../../../features/redux-toolkit/transactionSlice";
import { closeModal } from "../../../utils/modalUtils";
import { DisabledField } from "../../constant/fields/DisabledField";
import { formatDate } from "../../../utils/dateUtils";

/*
 * Name: AddNewTransaction.tsx
 * Description: Component for adding a new transaction (Income or Expense) with form validation and dynamic categories/subcategories.
 * Version: 1.0.0
 */

interface ValidationData {
  documentNumber: string;
  transactionType: string;
  description: string;
  date: string;
  amount: number;
  gst: number;
  payUsing: string;
  category: {
    id: number;
    name: string;
  };
  subCategory: {
    id: number;
    name: string;
  };
  crossReference?: string;
  receiptFile?: File;
}

// Validation schema using yup for form fields
const schema = yup.object().shape({
  transactionType: yup.string().required("Type is required"),
  date: yup
    .date()
    .required("Date is required")
    .typeError("Date is required")
    .max(new Date(), "Date cannot be in the future")
    .test("valid-date", "Invalid date", (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  category: yup
    .object()
    // .required("Category is required")
    .shape({
      id: yup.number().typeError("Category is required"),
      name: yup.string().required("Category name is required"),
    }),
  subCategory: yup
    .object()
    .required("Subcategory is required")
    .shape({
      id: yup
        .number()
        .required("Subcategory ID is required")
        .typeError("No Subcategory selected."),
      name: yup.string().required("Subcategory name is required"),
    }),
  amount: yup
    .number()
    .typeError("Amount should be a digit.")
    .required("Amount is required")
    .positive("Amount should be a positive digit.")
    .test(
      "maxDecimalPlaces",
      "Amount should have at most two decimal places.",
      (value: any) => {
        return /^\d+(\.\d{1,2})?$/.test(value); // Ensuring the amount has at most two decimal places
      }
    ),
  documentNumber: yup.string().required("Document No is required"),
  gst: yup
    .number()
    .typeError("GST Component should be a digit.")
    .required("GST Component is required")
    .positive("GST Component should be a positive digit.")
    .test(
      "maxDecimalPlaces",
      "GST Component should have at most two decimal places.",
      (value: any) => {
        return /^\d+(\.\d{1,2})?$/.test(value); // Ensuring the GST has at most two decimal places
      }
    )
    .when("amount", (amount: any, schema) => {
      if (amount) {
        return schema.test(
          "greaterThanOrEqual",
          "GST should be less than or equal to the Amount.",
          (gst: any) => {
            if (gst === undefined) return true; // Pass validation if GST is empty
            const amountNum = parseFloat(amount);
            const gstNum = parseFloat(gst);
            if (isNaN(amountNum) || isNaN(gstNum)) return true; // Skip validation for invalid numbers
            return gstNum <= amountNum; // Ensure GST is at most 50% of Amount
          }
        );
      }
      return schema; // Return the original schema if amount is not provided
    }),
  payUsing: yup.string().required("Paid Using is required"),
  crossReference: yup.string().optional(),
  description: yup.string().required("Description is required"),
  receiptFile: yup.mixed().optional(),
});

export const AddNewTransaction = () => {
  const dispatch = useAppDispatch();
  const { categories } =
    useAppSelector((state: any) => state.transaction) || []; // Access categories from the store
  const { transactionDetails } = useAppSelector(
    (state: any) => state.transaction
  );
  const closeRef: any = useRef(null);
  const [file, setFile] = useState(null); // State for handling file input
  const [subCategories, setSubCategories] = useState(
    [{ id: 0, name: "" }] // Default subcategories from the first category
  );
  const [currentDate, setCurrentDate] = useState<Date | null>(null); // Explicitly type the state

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema), // Using yup validation schema for the form
    defaultValues: {},
  });

  // Handle file input changes (receipt upload)
  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  // Handle category change and update subcategories based on the selected category
  const handleCategoryChange = (event: any) => {
    const selectedCategoryId = parseInt(event.target.value);
    const selectedCategory = categories.find(
      (cat: any) => cat.id === selectedCategoryId
    );

    if (selectedCategory) {
      setValue("category", {
        id: selectedCategory.id,
        name: selectedCategory.name,
      });
      clearErrors("category");

      // Set the subcategory based on the selected category
      setSubCategories(selectedCategory.subCategory || []);

      // Set the default subcategory based on the first subcategory of the selected category
      if (
        selectedCategory.subCategory &&
        selectedCategory.subCategory.length > 0
      ) {
        setValue("subCategory", {
          id: selectedCategory.subCategory[0].id,
          name: selectedCategory.subCategory[0].name,
        });
        clearErrors("subCategory.id");
        clearErrors("subCategory.name");
      }
    } else {
      setSubCategories([]);
      setValue("subCategory", {
        id: 0,
        name: "",
      });
    }
  };

  // Handle subcategory change and update the subcategory value
  const handleSubCategoryChange = (event: any) => {
    const selectedSubCategoryId = parseInt(event.target.value);

    const selectedSubCategory = subCategories.find(
      (subCat: any) => subCat.id === selectedSubCategoryId
    );

    if (selectedSubCategory) {
      setValue("subCategory", {
        id: selectedSubCategory.id,
        name: selectedSubCategory.name,
      });
      clearErrors("subCategory.name");
    }
  };

  // Handle the input precision for fields that require max two decimal places (Amount, GST)
  const handleTwoPrecision = (e: any, fieldName: keyof ValidationData) => {
    let value = e.target.value;

    // If the value is empty, allow it (this allows the user to clear the field)
    if (value === "") {
      setValue(fieldName, value, { shouldValidate: true });
      return;
    }

    // Regular expression to allow up to two decimal places
    const regex = /^\d+(\.\d{0,2})?$/; // Matches integers or numbers with 1 or 2 decimal places

    // If the value matches the regex, proceed and set the value
    if (regex.test(value)) {
      setValue(fieldName, value, { shouldValidate: true });
    }
  };

  // Handles success after adding a transaction
  const addedSuccessfully = (id: number) => {
    if (id) {
      closeRef.current.click(); // Close the form
      const successAlert = {
        icon: "success",
        title: "Added successfully",
        text: "The transaction was added successfully.",
        confirmButtonText: "OK",
      };
      closeRef.current.click(); // Duplicate close, can likely be removed
      reset(); // Reset the form
      dispatch(showAlert(successAlert)); // Show success alert
      dispatch(getTransactionList()); // Refresh transaction list
    }
  };

  // Handles errors during transaction process
  const handleError = (success: boolean, error: string) => {
    if (!success) {
      closeRef.current.click(); // Close the form
      reset(); // Reset the form
      const errorAlert = {
        icon: "error",
        title: `${error}`,
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Cancel",
      };
      dispatch(showAlert(errorAlert)); // Show error alert
    }
  };

  //   const selectedDate = new Date(e.target.value);
  //   console.log(selectedDate, "Before adding current time");

  //   if (!isNaN(selectedDate.getTime())) {
  //     // Get the current time
  //     const now = new Date();
  //     selectedDate.setHours(
  //       now.getHours(),
  //       now.getMinutes(),
  //       now.getSeconds(),
  //       now.getMilliseconds()
  //     );

  //     console.log(selectedDate, "After adding current time");
  //     setCurrentDate(selectedDate); // Update state with the modified date

  //     clearErrors("date"); // Clear error if date is valid

  //     // Only update the form state if currentDate is not null
  //     if (selectedDate) {
  //       setValue("date", selectedDate, {
  //         // Format as 'yyyy-mm-dd'
  //         shouldValidate: true,
  //         shouldDirty: true,
  //       });
  //     }
  //   }
  // };

  // Function to handle form submission
  const onSubmit = (data: any) => {
    console.log(data, "data");
    data.date = currentDate;
    dispatch(hideAlert());
    if (data.transactionType === "INCOME") {
      data.incomeAmount = data.amount;
      data.incomeGst = data.gst;
      data.expenseGst = "null";
      data.expenseGst = null; // Fix potential typo (double assignment)
    } else {
      data.expenseAmount = data.amount;
      data.expenseGst = data.gst;
      data.incomeAmount = "null";
      data.incomeGst = null;
    }

    // Remove unnecessary fields from data before dispatching
    delete data.account;
    delete data.gst;
    data.subCategory = { id: data.subCategory.id, name: data.subCategory.name }; // Keep only the ID and name of the subcategory
    dispatch(addTransaction(data)).then((res: any) => {
      if (!res.payload.success) {
        handleError(res.payload.success, res.payload.error);
        closeRef.current.click();
        reset();
      }
      if (res.payload.id) {
        addedSuccessfully(res.payload.id);
      }
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="incomeExpenseModal"
        aria-labelledby="incomeExpenseModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header px-5 py-4">
              <h5 className="modal-title gradient-text" id="addIncomeExpense">
                {transactionDetails?.id ? (
                  <>
                    {transactionDetails.transactionType
                      ? transactionDetails.transactionType
                          .charAt(0)
                          .toUpperCase() +
                        transactionDetails.transactionType
                          .slice(1)
                          .toLowerCase()
                      : ""}{" "}
                    Transaction ID: {transactionDetails.id}
                  </>
                ) : (
                  <> Add Income or Expense Transaction </>
                )}
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={() => {
                  dispatch(clearTransactionDetails());
                  closeModal("incomeExpenseModal");
                  reset();
                  setSubCategories([]);
                }}
              ></button>
            </div>
            {/* <hr className="border-top-only pb-3" /> */}
            <div className="modal-body px-5 py-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row row-gap-4">
                  {/* Type */}
                  <div className="col-md-6">
                    <label
                      htmlFor="transactionType"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Type (Income or Expense)
                    </label>
                    {transactionDetails?.transactionType ? (
                      <>
                        <DisabledField
                          value={transactionDetails.transactionType || ""}
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="transactionType"
                          {...register("transactionType")}
                        >
                          <option value="">Select Type</option>
                          <option value="INCOME">Income</option>
                          <option value="EXPENSE">Expense</option>
                        </select>
                        {errors.transactionType && (
                          <p className="error-text">
                            {errors.transactionType.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Document No */}
                  <div className="col-md-6">
                    <label
                      htmlFor="documentNumber"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Document No
                    </label>
                    {transactionDetails?.documentNumber ? (
                      <>
                        <DisabledField
                          value={transactionDetails.documentNumber || ""}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          className="form-control custom-input-popup"
                          id="documentNumber"
                          placeholder="Enter Document No"
                          {...register("documentNumber")}
                        />
                        {errors.documentNumber && (
                          <p className="error-text">
                            {errors.documentNumber.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label
                      htmlFor="amount"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Amount (Inc GST If Applicable)
                    </label>
                    {transactionDetails?.incomeAmount ||
                    transactionDetails?.expenseAmount ? (
                      <>
                        <DisabledField
                          value={
                            transactionDetails.incomeAmount ||
                            transactionDetails.expenseAmount ||
                            ""
                          }
                        />
                      </>
                    ) : (
                      <>
                        <input
                          value={getValues("amount")}
                          className="form-control custom-input-popup"
                          id="amount"
                          onInput={(e: any) => handleTwoPrecision(e, "amount")}
                          placeholder="0"
                          {...register("amount")}
                        />
                        {errors.amount && (
                          <p className="error-text">{errors.amount.message}</p>
                        )}
                      </>
                    )}
                  </div>

                  {/* GST Component */}
                  <div className="col-md-6">
                    <label
                      htmlFor="gst"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      GST Component
                    </label>
                    {transactionDetails?.gst ? (
                      <>
                        <DisabledField value={transactionDetails.gst || ""} />
                      </>
                    ) : (
                      <>
                        <input
                          value={getValues("gst")}
                          className="form-control custom-input-popup"
                          id="gst"
                          onInput={(e: any) => handleTwoPrecision(e, "gst")}
                          {...register("gst")}
                          placeholder="0"
                        />
                        {errors.gst && (
                          <p className="error-text">{errors.gst.message}</p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Paid Using */}
                  <div className="col-md-6">
                    <label
                      htmlFor="payUsing"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      {transactionDetails?.id
                        ? transactionDetails?.transactionType === "INCOME"
                          ? "Received Using"
                          : "Paid  Using"
                        : "Paid / Received Using"}
                    </label>{" "}
                    {transactionDetails?.id ? (
                      <>
                        <DisabledField
                          value={transactionDetails.payUsing || ""}
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="payUsing"
                          {...register("payUsing")}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option>Cash</option>
                          <option>Card</option>
                          <option>Visa</option>
                        </select>
                        {errors.payUsing && (
                          <p className="error-text">
                            {errors.payUsing.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Cross Reference */}
                  <div className="col-md-6">
                    <label
                      htmlFor="crossReference"
                      className="col-form-label custom-label-popup"
                    >
                      Cross Reference
                    </label>
                    {transactionDetails?.crossReference ? (
                      <>
                        <DisabledField
                          value={transactionDetails.crossReference || ""}
                        />
                      </>
                    ) : transactionDetails?.crossReference === null ||
                      transactionDetails?.crossReference === "" ? null : (
                      <>
                        <input
                          type="text"
                          className="form-control custom-input-popup"
                          id="crossReference"
                          {...register("crossReference")}
                          placeholder="Enter Cross Reference"
                        />
                        {errors.crossReference && (
                          <p className="error-text">
                            {errors.crossReference.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Description */}
                  <div className="col-md-6">
                    <label
                      htmlFor="description"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Description
                    </label>
                    {transactionDetails?.description ? (
                      <>
                        <DisabledField
                          value={transactionDetails.description || ""}
                        />
                      </>
                    ) : (
                      <>
                        <textarea
                          className="form-control custom-description"
                          id="description"
                          spellCheck
                          {...register("description")}
                          placeholder="Enter Description"
                          rows={4}
                        />
                        {errors.description && (
                          <p className="error-text">
                            {errors.description.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  <div className="col-md-6">
                    {/* Upload Receipt */}
                    <div className="col-md-12">
                      <label
                        // htmlFor="receipt-file"
                        className="col-form-label custom-label-popup"
                      >
                        Upload Receipt
                      </label>
                      {transactionDetails?.id ? (
                        <>
                          <DisabledField
                            value={
                              transactionDetails?.receiptFile || "Not Available"
                            }
                          />
                        </>
                      ) : (
                        <>
                          <div className="d-flex">
                            <input
                              type="file"
                              className="form-control custom-input-popup centered-file-input"
                              id="receipt-file"
                              onChange={handleFileChange}
                            />
                          </div>
                          {errors.receiptFile && (
                            <p className="error-text">
                              {errors.receiptFile.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    {/* Date */}
                    <div className="col-md-12 py-3">
                      <label
                        htmlFor="date"
                        className={`col-form-label custom-label-popup ${
                          transactionDetails?.id ? "" : "mandatory"
                        }`}
                      >
                        Date
                      </label>
                      {transactionDetails?.date ? (
                        <>
                          <DisabledField
                            value={formatDate(transactionDetails.date) || ""}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="date"
                            className="form-control custom-input-popup date-input-color"
                            id="date"
                            {...register("date")}
                            onChange={(e) => {
                              const selectedDate = new Date(e.target.value);
                              if (!isNaN(selectedDate.getTime())) {
                                // Get the current time
                                const now = new Date();
                                selectedDate.setHours(
                                  now.getHours(),
                                  now.getMinutes(),
                                  now.getSeconds(),
                                  now.getMilliseconds()
                                );
                                setCurrentDate(selectedDate);
                                clearErrors("date"); // Clear the error message if the date is valid
                              }
                            }}
                          />
                          {errors.date && (
                            <p className="error-text">{errors.date.message}</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Category */}
                  <div className="col-md-6">
                    <label
                      htmlFor="category"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Category
                    </label>{" "}
                    {transactionDetails?.category?.category ? (
                      <>
                        <DisabledField
                          value={transactionDetails?.category?.category || ""}
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="category"
                          {...register("category.id")}
                          onChange={handleCategoryChange} // Trigger category change handler
                        >
                          <option value="">Select Category</option>
                          {categories.map((cat: any) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                        {errors.category && (
                          <p className="error-text">
                            {errors.category.message}
                          </p>
                        )}
                        {errors.category?.id && (
                          <p className="error-text">
                            {errors.category.id.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/* Sub-Category */}
                  <div className="col-md-6">
                    <label
                      htmlFor="subCategory"
                      className={`col-form-label custom-label-popup ${
                        transactionDetails?.id ? "" : "mandatory"
                      }`}
                    >
                      Sub-Category
                    </label>{" "}
                    {transactionDetails?.subCategory?.subCategory ? (
                      <>
                        <DisabledField
                          value={
                            transactionDetails.subCategory.subCategory || ""
                          }
                        />
                      </>
                    ) : (
                      <>
                        <select
                          className="form-select custom-select-popup"
                          id="subCategory"
                          // value={getValues("subCategory.name")}
                          {...register("subCategory.id")} // Register subcategory ID
                          onChange={handleSubCategoryChange} // Trigger subcategory change handler
                        >
                          <option value="" disabled>
                            Select Sub-Category
                          </option>
                          {subCategories.length > 0 ? (
                            <>
                              {subCategories.map((subCat: any) => (
                                <option key={subCat.id} value={subCat.id}>
                                  {subCat.name}
                                </option>
                              ))}
                            </>
                          ) : (
                            <option value="">Select Sub-Category</option>
                          )}
                        </select>
                        {errors.subCategory?.name && (
                          <p className="error-text">
                            {errors.subCategory.name.message}
                          </p>
                        )}
                        {/* {errors.subCategory?.id && (
                      <p className="error-text">
                        {errors.subCategory.id.message}
                      </p>
                    )} */}
                      </>
                    )}
                  </div>
                </div>

                {transactionDetails?.id ? (
                  <>
                    <div className="modal-footer p-0 pt-4 pb-4">
                      <div
                        className="custom-gradient-btn text-center d-flex align-items-center"
                        onClick={() => {
                          dispatch(clearTransactionDetails());
                          closeModal("incomeExpenseModal");
                          reset();
                        }}
                        style={{
                          padding: "7px 56px",
                          backgroundColor: "#00429B",
                          border: "none",
                          height: "44px",
                        }}
                      >
                        Close
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="modal-footer p-0 pt-4 pb-4">
                      <button type="submit" className="custom-gradient-btn">
                        Create
                      </button>
                      <button type="submit" className="custom-ghost-btn">
                        Add to Regular Transaction
                      </button>
                      <button type="button" className="custom-ghost-btn">
                        Use a Regular Transaction
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
