import React, { useEffect } from "react";
import Swal from "sweetalert2";

/*
 * Name: Alerts.tsx
 * Description: A reusable alert component for displaying notifications using SweetAlert2.
 *              The component allows customization of icon, title, text, and button text.
 *              It can also automatically close after a specified time and supports positioning of the alert on the screen.
 * Version: 1.0.0
 */

interface AlertsProps {
  icon?: "success" | "error" | "warning" | "info" | "question"; // Define possible values for icon
  title?: string;
  text?: string;
  timer?: number;
  position?:
    | "top"
    | "top-start"
    | "top-end"
    | "center"
    | "bottom"
    | "bottom-start"
    | "bottom-end";
  confirmButtonText?: string; // Optional, to customize button text
}

export const Alerts: React.FC<AlertsProps> = ({
  icon,
  title,
  text,
  confirmButtonText = "OK", // Default value for confirmButtonText
  timer,
  position = "center", // Default position
}) => {
  // Trigger SweetAlert2 on mount (or as needed)
  useEffect(() => {
    Swal.fire({
      icon: icon, // Use the passed icon
      title: title, // Use the passed title
      text: text, // Use the passed text
      confirmButtonText: confirmButtonText, // Use the passed confirm button text
      timer: timer, // Optional: auto-close after a certain time
      position: position, // Set position as per the prop
    });
  }, [icon, title, text, confirmButtonText, timer, position]); // Re-run effect if any prop changes

  return null; // Since the alert is being handled by SweetAlert2, no need to return anything
};
