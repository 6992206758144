import React from "react"; // Importing React
import "./loader.css"; // Importing CSS for loader styling

/*
 * Name: Loader.tsx
 * Description: Simple loader component to indicate loading state in the application.
 * Version: 1.0.0
 */

export const Loader = () => {
  return (
    <>
      <div className="w-100 h-100">
        <div className="loader-body">
          {" "}
          {/* Container for the loader */}
          <div className="custom-loader"></div> {/* Loader animation */}
        </div>
      </div>
    </>
  );
};

export {}; // Exporting an empty object to avoid potential issues with TypeScript
